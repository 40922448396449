/**************************************************/
/*Control bar*/
/**************************************************/
.hide {
    display: none;
}

.disable {
    pointer-events: none;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

:-webkit-full-screen {
    width: 100%;
    top: 0;
    z-index: -100;
    background: none;
}

:-ms-fullscreen {
    width: auto;
    height: auto;
    margin: auto;
}

.time-display,
.duration-display {
    padding: 11px;
    color: white;
    font-weight: normal;
    font-size: 0.9em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.time-display {
    float: left;
}

.duration-display {
    float: right;
}

.live-icon {
    cursor: pointer;
}
.live-icon.live {
    color: red !important;
    pointer-events: none;
    cursor: default;
}

.btn-play-pause {
    float: left;
    padding: 9px 10px;
    cursor: pointer;
}

.control-icon-layout {
    float: right;
    padding: 9px 10px;
    cursor: pointer;
}

.btn-fullscreen {
    margin-right: 10px;
}

.volumebar {
    float: right;
    width: 70px;
}

.video-controller {
    min-height: 35px;
    z-index: 2147483646;
}

.video-controller-fullscreen {
    position: fixed;
    z-index: 2147483647;
    width: 100%;
    bottom: 0;
    left: 0;
}

.menu,
.video-controller {
    background-color: black;
}

.menu-item-unselected,
.menu-item-selected {
    font-weight: normal;
    font-size: 0.9em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.menu-item-unselected {
    color: white;
}

.menu-item-over,
.menu-item-selected {
    background-color: white;
    color: black;
}

.menu-sub-menu-title {
    background-color: #191919;
    padding-left: 2px;
    font-weight: bold;
    font-size: 1em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.menu-item-selected {
    opacity: 0.7;
}

.menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu li {
    padding: 0 10px;
    cursor: pointer;
}

.menu {
    position: absolute;
}

#bitrateMenu .menu-sub-menu-title {
    min-width: 150px;
}

@font-face {
    font-family: "icomoon";
    src: url("icomoon.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.icon-play,
.icon-pause,
.icon-caption,
.icon-mute-off,
.icon-mute-on,
.icon-fullscreen-enter,
.icon-fullscreen-exit,
.icon-tracks,
.icon-bitrate {
    font-family: "icomoon";
    font-size: 20px;
    color: white;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
}

.icon-fullscreen-enter:before {
    content: "\e90b";
}
.icon-fullscreen-exit:before {
    content: "\e90c";
}
.icon-play:before {
    content: "\e910";
}
.icon-pause:before {
    content: "\e911";
}
.icon-mute-on:before {
    content: "\e909";
}
.icon-mute-off:before {
    content: "\e918";
}
.icon-caption:before {
    content: "\e901";
}
.icon-bitrate:before {
    content: "\e905";
}
.icon-tracks:before {
    content: "\e90a";
}

.seekContainer {
    display: flex;
    overflow: auto;
    overflow-y: hidden;
    overflow-x: hidden;
}

input[type="range"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    height: 14px;
    border: none;
    margin: 12px 5px;
    padding: 1px 2px;
    border-radius: 5px;
    background: #232528;
    box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
    -webkit-box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
    outline: none; /* no focus outline */
}

input[type="range"]::-moz-focus-outer {
    border: 0;
}

input[type="range"]::-moz-range-track {
    border: inherit;
    background: transparent;
}

input[type="range"]::-ms-track {
    border: inherit;
    color: transparent; /* don't drawn vertical reference line */
    background: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: transparent;
}

input[type="range"]::-ms-tooltip {
    display: none;
}

/* thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 8px;
    border: none;
    border-radius: 2px;
    background-color: rgb(0, 150, 215);
}
input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 8px;
    border: none;
    border-radius: 2px;
    background-color: rgb(0, 150, 215);
}

input[type="range"]::-ms-thumb {
    width: 15px;
    height: 8px;
    border: none;
    border-radius: 2px;
    background-color: rgb(0, 150, 215);
}

.thumbnail-container {
    position: absolute;
    text-align: center;
}

.thumbnail-elem {
    position: relative;
    box-shadow: 0px 0px 0.9em #000000;
    transform-origin: center bottom;
}

.thumbnail-time-label {
    position: relative;
    bottom: 1.8em;
    display: table;
    margin: 0 auto;
    padding: 2px 5px 2px 5px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.seekbar-complete {
    width: 100%;
    height: 7px;
    margin-top: 16px;
    background: #999a99;
    position: relative;
    overflow: hidden;
}

.seekbar-buffer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 7px;
    background: lightgray;
}

.seekbar-play {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 7px;
    background: rgb(0, 150, 215);
}
